import React, {useEffect} from "react";
import {
    Create,
    Form,
    Input,
    Select,
    useForm,
    useSelect,
    DatePicker, Button, Space,
} from "@pankod/refine-antd";
import {IResourceComponentsProps} from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {ISupply, IWarehouse} from "./model";
import {ISupplier} from "../suppliers";
import {IProduct} from "../products";

export const SupplyCreate: React.FC<IResourceComponentsProps> = () => {
    const {form, formProps, saveButtonProps} = useForm<ISupply>({
        warnWhenUnsavedChanges: true,
    });
    const {selectProps: supplierSelectProps} = useSelect<ISupplier>({
        resource: "suppliers",
        optionLabel: "name",
        metaData: {
            fields: ["id", "name"],
        },
    });

    const {selectProps: warehouseSelectProps, queryResult: warehouseQueryResult} = useSelect<IWarehouse>({
        resource: "warehouses",
        optionLabel: "name",
        metaData: {
            fields: ["id", "name"],
        },
    });

    const {selectProps: productsSelectProps} = useSelect<IProduct>({
        resource: "products",
        optionLabel: "name",
        metaData: {
            fields: ["id", "name"],
        },
    });

    useEffect(() => {
        form.setFieldsValue({warehouseID: warehouseQueryResult.data?.data[0].id})
    }, [form, warehouseQueryResult])

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="PaidAt" name="paidAt">
                    <DatePicker/>
                </Form.Item>
                <Form.Item
                    label="Supplier"
                    name="supplierID"
                    rules={[{required: true}]}
                >
                    <Select {...supplierSelectProps} />
                </Form.Item>
                <Form.Item
                    label="Warehouse"
                    name="warehouseID"
                    rules={[{required: true}]}
                >
                    <Select {...warehouseSelectProps} />
                </Form.Item>
                <Form.Item label="Comments" name="comments">
                    <Input/>
                </Form.Item>

                <Form.List name={"supplyItems"}>
                    {(fields, { add, remove }, operator) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                name={[field.name, 'productID']}
                                                rules={[{ required: true }]}
                                            >
                                                <Select {...productsSelectProps} placeholder="product" style={{ width: 130 }}/>
                                            </Form.Item>

                                            <Form.Item
                                                name={[field.name, 'qty']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="qty" style={{ width: 60 }}/>
                                            </Form.Item>

                                            <Form.Item
                                                name={[field.name, 'pricePerItem']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="price" style={{ width: 80 }}/>
                                            </Form.Item>

                                            <Form.Item
                                                name={[field.name, 'priceTotal']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="price" style={{ width: 80 }}/>
                                            </Form.Item>

                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </Space>
                                    );
                                })}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        More products
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            </Form>
        </Create>
    );
};
