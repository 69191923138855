import React from "react";
import {RefreshButton, Show, Typography} from "@pankod/refine-antd";
import {IResourceComponentsProps, useShow} from "@pankod/refine-core";
import {IUser} from "./model";

const { Title, Text } = Typography;

const metaData = {
    fields: [
        "id",
        "email",
        "createdAt",
    ],
};

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IUser>({
        metaData,
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading} headerProps={{ extra: <RefreshButton metaData={metaData} />}}>
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Email</Title>
            <Text>{record?.email}</Text>

            <Title level={5}>Created at</Title>
            <Text>{record?.createdAt}</Text>
        </Show>
    );
};
