import React from "react";
import {Refine} from "@pankod/refine-core";
import {RefineKbarProvider} from "@pankod/refine-kbar";
import {
    Layout,
    ReadyPage,
    notificationProvider,
    ErrorComponent,
    AuthPage,
    Icons,
} from "@pankod/refine-antd";
import {GoogleOutlined, GithubOutlined} from "@ant-design/icons";
import routerProvider from "@pankod/refine-react-router-v6";
import {OffLayoutArea, Title} from "components";
import {UserEdit, UserList, UserCreate, UserShow} from "users";
import {dataProvider} from "./entDataProvider";

import "@pankod/refine-antd/dist/styles.min.css";
import {authProvider} from "./authProvider";
import {ProductCreate, ProductEdit, ProductList, ProductShow} from "./products";
import {CategoryCreate, CategoryEdit, CategoryList, CategoryShow} from "./categories";
import {AuthenticatedPOS} from "./pos/pos";
import {SupplierCreate, SupplierEdit, SupplierList, SupplierShow} from "./suppliers";
import {SupplyCreate, SupplyEdit, SupplyList, SupplyShow} from "./supply";
import {PlaceCreate, PlaceEdit, PlaceList} from "./places";

const apiBaseURL = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "https://api.flora24.online"

const {RouterComponent} = routerProvider;
const DashboardRouterComponent = () => <RouterComponent basename="/dashboard"/>

const App: React.FC = () => {
    return (
        <RefineKbarProvider>
            <Refine
                Title={Title}
                OffLayoutArea={OffLayoutArea}
                authProvider={authProvider}
                routerProvider={{
                    ...routerProvider,
                    // RouterComponent: DashboardRouterComponent,
                    routes: [
                        {
                            path: "/register",
                            element: (
                                <AuthPage
                                    type="register"
                                    providers={[
                                        {
                                            name: "google",
                                            label: "Sign in with Google",
                                            icon: (
                                                <GoogleOutlined
                                                    style={{
                                                        fontSize: 24,
                                                        lineHeight: 0,
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            name: "github",
                                            label: "Sign in with GitHub",
                                            icon: (
                                                <GithubOutlined
                                                    style={{
                                                        fontSize: 24,
                                                        lineHeight: 0,
                                                    }}
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            ),
                        },
                        {
                            path: "/forgot-password",
                            element: <AuthPage type="forgotPassword"/>,
                        },
                        {
                            path: "/update-password",
                            element: <AuthPage type="updatePassword"/>,
                        },
                        {
                            path: "/pos",
                            element: <AuthenticatedPOS/>,
                            layout: false,
                        },
                    ],
                }}
                dataProvider={dataProvider(apiBaseURL)}
                resources={[
                    {
                        name: "categories",
                        list: CategoryList,
                        create: CategoryCreate,
                        edit: CategoryEdit,
                        show: CategoryShow
                    },
                    {
                        icon: <Icons.BugOutlined/>,
                        name: "products",
                        list: ProductList,
                        create: ProductCreate,
                        edit: ProductEdit,
                        show: ProductShow
                    },
                    {
                        icon: <Icons.UsergroupAddOutlined/>,
                        name: "users",
                        list: UserList,
                        create: UserCreate,
                        edit: UserEdit,
                        show: UserShow
                    },
                    {
                        icon: <Icons.UsergroupAddOutlined/>,
                        name: "suppliers",
                        list: SupplierList,
                        create: SupplierCreate,
                        edit: SupplierEdit,
                        show: SupplierShow
                    },
                    {
                        icon: <Icons.UsergroupAddOutlined/>,
                        name: "supplies",
                        list: SupplyList,
                        create: SupplyCreate,
                        edit: SupplyEdit,
                        show: SupplyShow
                    },
                    {
                        icon: <Icons.UsergroupAddOutlined/>,
                        name: "places",
                        list: PlaceList,
                        create: PlaceCreate,
                        edit: PlaceEdit
                    }
                ]}
                Layout={Layout}
                ReadyPage={ReadyPage}
                LoginPage={() => (
                    <AuthPage
                        providers={[
                            {
                                name: "google",
                                label: "Sign in with Google",
                                icon: (
                                    <GoogleOutlined
                                        style={{fontSize: 24, lineHeight: 0}}
                                    />
                                ),
                            },
                            {
                                name: "github",
                                label: "Sign in with GitHub",
                                icon: (
                                    <GithubOutlined
                                        style={{fontSize: 24, lineHeight: 0}}
                                    />
                                ),
                            },
                        ]}
                    />
                )}
                notificationProvider={notificationProvider}
                catchAll={<ErrorComponent/>}
            />
        </RefineKbarProvider>
    );
};

export default App;
