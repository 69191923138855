import {
    Card,
    Col,
    DeleteButton,
    EditButton, Form, FormProps,
    List,
    Row,
    ShowButton,
    Space,
    Table,
    Icons,
    useTable, Input, Button
} from "@pankod/refine-antd";
import {IFilter, ISupplier} from "./model";
import React, {useEffect, useState} from "react";
import {CrudFilters, HttpError} from "@pankod/refine-core";
import {CursorPagination} from "components";
import {Cursors} from "../entDataProvider";

const DEFAULT_CURSORS = {first: 50};

export const SupplierList: React.FC = () => {
    const [cursors, setCursors] = useState<Cursors>(DEFAULT_CURSORS);

    const {tableProps, searchFormProps, setFilters, tableQueryResult} = useTable<ISupplier, HttpError, IFilter>({
        hasPagination: false,
        metaData: {
            fields: [
                "id",
                "name",
                "email",
                "phone",
                "comment",
            ],
        },
        initialFilter: [
            {
                field: "cursors",
                operator: "eq",
                value: DEFAULT_CURSORS,
            }
        ],
        onSearch: (params) => {
            const {query, cursors} = params;

            const filters: CrudFilters = [
                {
                    field: "title",
                    operator: "contains",
                    value: query,
                },
                {
                    field: "cursors",
                    operator: "eq",
                    value: cursors,
                }
            ];

            return filters;
        }
    });

    useEffect(() => {
        setFilters([{field: "cursors", operator: "eq", value: cursors}])
    }, [cursors, setFilters])

    return (
        <Row gutter={[16, 16]}>
            <Col lg={6} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps}/>
                </Card>
            </Col>
            <Col lg={18} xs={24}>
                <List>
                    <Table<ISupplier> {...tableProps} rowKey="id">
                        <Table.Column dataIndex="id" title="id"/>
                        <Table.Column
                            dataIndex="name"
                            title="name"
                            sorter={true}
                        />
                        <Table.Column dataIndex="email" title="email"/>
                        <Table.Column dataIndex="phone" title="phone"/>
                        <Table.Column dataIndex="comment" title="comment"/>
                        <Table.Column<ISupplier>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton hideText size="small" recordItemId={record.id}/>
                                    <ShowButton hideText size="small" recordItemId={record.id}/>
                                    <DeleteButton hideText size="small" recordItemId={record.id}/>
                                </Space>
                            )}
                        />
                    </Table>
                    <CursorPagination data={tableQueryResult.data} cursors={cursors} setCursors={setCursors}/>
                </List>
            </Col>
        </Row>
    )
}

const Filter: React.FC<{ formProps: FormProps }> = ({formProps}) => {
    return (
        <Form layout="vertical" {...formProps}>
            <Form.Item label="Search" name="query">
                <Input
                    placeholder="Name.."
                    prefix={<Icons.SearchOutlined/>}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
