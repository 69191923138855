import React from "react";
import {Avatar, RefreshButton, Show, Typography} from "@pankod/refine-antd";
import {IResourceComponentsProps, useShow} from "@pankod/refine-core";
import {IProduct} from "./model";

const {Title, Text} = Typography;

const metaData = {
    fields: [
        "id",
        "name",
        "type",
        "unit",
        "cost",
        "price",
        "image",
        "createdAt",
    ],
};

export const ProductShow: React.FC<IResourceComponentsProps> = () => {
    const {queryResult} = useShow<IProduct>({
        metaData,
    });
    const {data, isLoading} = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading} headerProps={{extra: <RefreshButton metaData={metaData}/>}}>
            {record?.image &&
                <Avatar
                    style={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "200px",
                    }}
                    src={record?.image}
                />
            }

            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>

            <Title level={5}>type</Title>
            <Text>{record?.type}</Text>

            <Title level={5}>Unit</Title>
            <Text>{record?.unit}</Text>

            <Title level={5}>Price</Title>
            <Text>{record?.price}</Text>

            <Title level={5}>Created at</Title>
            <Text>{record?.createdAt}</Text>
        </Show>
    );
};
