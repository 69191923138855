import React from "react";
import {RefreshButton, Show, Typography} from "@pankod/refine-antd";
import {IResourceComponentsProps, useShow} from "@pankod/refine-core";
import {ISupply} from "./model";

const {Title, Text} = Typography;

const metaData = {
    fields: [
        "id",
        "paidAt",
        "comments",
        "supplier",
        "warehouse",
        "supplyItems",
        "createdAt",
    ],
};

export const SupplyShow: React.FC<IResourceComponentsProps> = () => {
    const {queryResult} = useShow<ISupply>({
        metaData,
    });
    const {data, isLoading} = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading} headerProps={{extra: <RefreshButton metaData={metaData}/>}}>
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Supplier</Title>
            <Text>{record?.supplier?.name}</Text>

            <Title level={5}>Created at</Title>
            <Text>{record?.createdAt}</Text>
        </Show>
    );
};
