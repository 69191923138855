import React from "react";
import {Create, Form, Input, useForm} from "@pankod/refine-antd";
import {IResourceComponentsProps} from "@pankod/refine-core";
import {IPlace} from "./model";

export const PlaceCreate: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IPlace>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Name" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Address" name="address">
                    <Input/>
                </Form.Item>
            </Form>
        </Create>
    );
};
