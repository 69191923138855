import React from "react";
import {Create, Form, Input, useForm} from "@pankod/refine-antd";
import {IResourceComponentsProps} from "@pankod/refine-core";
import {ISupplier} from "./model";

export const SupplierCreate: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<ISupplier>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Name" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Email" name="email">
                    <Input/>
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                    <Input/>
                </Form.Item>
                <Form.Item label="Comment" name="comment">
                    <Input/>
                </Form.Item>
            </Form>
        </Create>
    );
};
