import {Button, Select, Space} from "@pankod/refine-antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

// @ts-ignore
export const CursorPagination = ({data, cursors, setCursors}) => {
    const handleChangeLimit = (value: number) => {
        if (cursors.after !== undefined) {
            setCursors({
                ...cursors,
                first: undefined,
                last: value,
            })
        } else {
            setCursors({
                ...cursors,
                first: value,
                last: undefined,
            })
        }
    }

    const handlePrevious = () => {
        setCursors({
            before: data?.pageInfo?.startCursor,
            last: cursors.last || cursors.first,
            after: undefined,
            first: undefined,
        })
    }

    const handleNext = () => {
        setCursors({
            after: data?.pageInfo?.endCursor,
            first: cursors.first || cursors.last,
            before: undefined,
            last: undefined,
        })
    }

    if (!data?.pageInfo?.hasPreviousPage && !data?.pageInfo?.hasNextPage) {
        return <></>;
    }

    return (
        <div>
            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center', marginTop: '1em'}}>
                <Button disabled={!Boolean(data?.pageInfo?.hasPreviousPage)}
                        onClick={handlePrevious}>
                    <LeftOutlined/>
                </Button>
                <Button disabled={!Boolean(data?.pageInfo?.hasNextPage)}
                        onClick={handleNext}>
                    <RightOutlined/>
                </Button>
                <Select
                    defaultValue={3}
                    // value={cursors.first || cursors.last}
                    onChange={handleChangeLimit}
                    options={pageLimitOptions}
                />
                <div>Total: {data?.total}</div>
            </Space>
        </div>
    );
};

const pageLimitOptions = [
    {
        value: 2,
        label: '2 / page',
    },
    {
        value: 3,
        label: '3 / page',
    },
    {
        value: 5,
        label: '5 / page',
    },
    {
        value: 10,
        label: '10 / page',
    },
];
