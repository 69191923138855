import React from "react";
import {Edit, Form, Input, useForm} from "@pankod/refine-antd";
import {HttpError, IResourceComponentsProps} from "@pankod/refine-core";
import {IPlace} from "./model";

export const PlaceEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IPlace, HttpError, IPlace>({
        metaData: {
            fields: [
                "id",
                "name",
                "address",
            ]
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Name" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Address" name="address">
                    <Input/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
