import { Logo } from "./styled";

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    return (
        <Logo>
            {collapsed ? (
                <img src="/dashboard/images/logo-small.png" alt="flora24.online" />
            ) : (
                <img src="/dashboard/images/logo.png" alt="flora24.online" />
            )}
        </Logo>
    );
};
