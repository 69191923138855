import React from "react";
import {Edit, Form, Input, useForm} from "@pankod/refine-antd";
import {HttpError, IResourceComponentsProps} from "@pankod/refine-core";
import {ISupplier} from "./model";

export const SupplierEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<ISupplier, HttpError, ISupplier>({
        metaData: {
            fields: [
                "id",
                "name",
                "email",
                "phone",
                "comment",
            ]
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Name" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Email" name="email">
                    <Input/>
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                    <Input/>
                </Form.Item>
                <Form.Item label="Comment" name="comment">
                    <Input/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
