import {Cursors} from "../entDataProvider";

export interface IUser {
    id: string
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    status: string;
    createdAt: string;
}

export interface IFilter {
    query: string
    status: string
    createdAt: [any, any];
    cursors: Cursors
}

export const userStatuses = [
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Inactive",
        value: "inactive",
    },
];
