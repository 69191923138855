import React from "react";
import {Create, Form, Input, Select, useForm} from "@pankod/refine-antd";
import {IResourceComponentsProps} from "@pankod/refine-core";
import {IUser, userStatuses} from "./model";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IUser>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Email" name="email" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Password" name="password" rules={[{required: true}]}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item label="First Name" name="firstName" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Last Name" name="lastName" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Status" name="status" rules={[{required: true}]}>
                    <Select options={userStatuses} />
                </Form.Item>
            </Form>
        </Create>
    );
};
