import {
    Card,
    Col,
    DateField,
    DeleteButton,
    EditButton, Form, FormProps,
    List,
    Row,
    ShowButton,
    Space,
    Table,
    Icons,
    useTable, Input, Select, DatePicker, Button, Tag
} from "@pankod/refine-antd";
import {IFilter, IUser, userStatuses} from "./model";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {CrudFilters, HttpError} from "@pankod/refine-core";
import {CursorPagination} from "components";
import {Cursors} from "../entDataProvider";

const DEFAULT_CURSORS = {first: 3};

export const UserList: React.FC = () => {
    const [cursors, setCursors] = useState<Cursors>(DEFAULT_CURSORS);

    const {tableProps, searchFormProps, setFilters, tableQueryResult} = useTable<IUser, HttpError, IFilter>({
        hasPagination: false,
        // syncWithLocation: true,
        metaData: {
            fields: [
                "id",
                "email",
                "createdAt",
                "status",
            ],
        },
        initialFilter: [
            {
                field: "cursors",
                operator: "eq",
                value: DEFAULT_CURSORS,
            }
        ],
        onSearch: (params) => {
            const {query, status, createdAt, cursors} = params;

            const filters: CrudFilters = [
                {
                    field: "createdAt",
                    operator: "gte",
                    value: createdAt ? createdAt[0].toISOString() : undefined,
                },
                {
                    field: "createdAt",
                    operator: "lte",
                    value: createdAt ? createdAt[1].toISOString() : undefined,
                },
                {
                    field: "email",
                    operator: "contains",
                    value: query,
                },
                {
                    field: "status",
                    operator: "eq",
                    value: status,
                },
                {
                    field: "cursors",
                    operator: "eq",
                    value: cursors,
                }
            ];

            return filters;
        }
    });

    useEffect(() => {
        setFilters([{field: "cursors", operator: "eq", value: cursors}])
    }, [cursors, setFilters])

    return (
        <Row gutter={[16, 16]}>
            <Col lg={6} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps}/>
                </Card>
            </Col>
            <Col lg={18} xs={24}>
                <List>
                    <Table<IUser> {...tableProps} rowKey="id">
                        {/*<Table.Column dataIndex="id" title="id"/>*/}
                        <Table.Column
                            dataIndex="email"
                            title="email"
                            sorter={true}
                        />
                        <Table.Column
                            dataIndex="createdAt"
                            title="createdAt"
                            sorter={true}
                            render={(value) => <DateField format="LLL" value={value}/>}
                        />
                        <Table.Column<IUser>
                            title="Active"
                            dataIndex="active"
                            align="center"
                            render={(_, record) => {
                                let color;
                                switch (record.status) {
                                    case "active":
                                        color = "green";
                                        break;
                                    case "inactive":
                                        color = "red";
                                        break;
                                    default:
                                        color = "";
                                        break;
                                }
                                return <Tag color={color}>
                                    {record.status === "active" ? <CheckOutlined/> : <CloseOutlined/>}
                                </Tag>
                            }}
                        />
                        <Table.Column<IUser>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton hideText size="small" recordItemId={record.id}/>
                                    <ShowButton hideText size="small" recordItemId={record.id}/>
                                    <DeleteButton hideText size="small" recordItemId={record.id}/>
                                </Space>
                            )}
                        />
                    </Table>
                    <CursorPagination data={tableQueryResult.data} cursors={cursors} setCursors={setCursors}/>
                </List>
            </Col>
        </Row>
    )
}

const {RangePicker} = DatePicker;

const Filter: React.FC<{ formProps: FormProps }> = ({formProps}) => {
    return (
        <Form layout="vertical" {...formProps}>
            <Form.Item label="Search" name="query">
                <Input
                    placeholder="ID, Email, Content, etc."
                    prefix={<Icons.SearchOutlined/>}
                />
            </Form.Item>
            <Form.Item label="Status" name="status">
                <Select
                    allowClear
                    options={userStatuses}
                    placeholder="Status"
                />
            </Form.Item>
            <Form.Item label="Created At" name="createdAt">
                <RangePicker/>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
