import React from "react";
import {Edit, Form, Input, useForm} from "@pankod/refine-antd";
import {HttpError, IResourceComponentsProps} from "@pankod/refine-core";
import {ICategory} from "./model";

export const CategoryEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<ICategory, HttpError, ICategory>({
        metaData: {
            fields: [
                "id",
                "title",
            ]
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Title" name="title" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
