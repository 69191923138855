import {Card, Col, Row} from "@pankod/refine-antd";
import {Authenticated} from "@pankod/refine-core";
import React from "react";
import styled from "@emotion/styled";

import { Layout } from 'antd';

const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;

export const Background = styled.div`
  background-color: #eceff1;
  height: 100%;
`;

const Products = () => {
    return <Row gutter={[16, 16]}>
        <Col span={4}>
            <Card
                hoverable
                cover={<img height={120} src="https://img-1.skyservice.pro/uploads/images/22633836d921969d7a4bb67ebfd55707/fFaSN9RHQrDzKZdd.png" />}
            >
                <Meta title="Свята" />
            </Card>
        </Col>
        <Col span={4}>
            <Card
                hoverable
                cover={<img height={120} src="https://content1.rozetka.com.ua/goods/images/big_tile/298889140.jpg" />}
            >
                <Meta title="Декор" />
            </Card>
        </Col>
        <Col span={4}>
            <Card
                hoverable
                cover={<img height={120} src="https://wowballoons.com.ua/wp-content/uploads/2019/10/shariki-s-konfetti.jpg" />}
            >
                <Meta title="Кульки" />
            </Card>
        </Col>
    </Row>
}

export const AuthenticatedPOS = () => {
    return (
        <Authenticated>
            <Row style={{height: "100vh", backgroundColor: "#eceff1"}}>
                <Col span={16}>
                    <Row gutter={[16, 16]}>
                        <Col span={2}>
                            2
                        </Col>
                        <Col span={22}>
                            {/*<Search/>*/}
                            <Products/>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    col-6 col-pull-18
                </Col>
            </Row>
        </Authenticated>
    );
};
