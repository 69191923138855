import {
    Col,
    List,
    Row,
    ShowButton,
    Space,
    Table, Tag,
    useTable
} from "@pankod/refine-antd";
import {IFilter, ISupply} from "./model";
import React, {useEffect, useState} from "react";
import {CrudFilters, HttpError} from "@pankod/refine-core";
import {CursorPagination} from "components";
import {Cursors} from "../entDataProvider";
import {IUser} from "../users";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const DEFAULT_CURSORS = {first: 50};

export const SupplyList: React.FC = () => {
    const [cursors, setCursors] = useState<Cursors>(DEFAULT_CURSORS);

    const {tableProps, setFilters, tableQueryResult} = useTable<ISupply, HttpError, IFilter>({
        hasPagination: false,
        metaData: {
            fields: [
                "id",
                "paidAt",
                {
                    supplier: ["name"],
                },
                {
                    warehouse: ["name"],
                },
            ],
        },
        initialFilter: [
            {
                field: "cursors",
                operator: "eq",
                value: DEFAULT_CURSORS,
            }
        ],
        onSearch: (params) => {
            const {query, cursors} = params;

            const filters: CrudFilters = [
                {
                    field: "name",
                    operator: "contains",
                    value: query,
                },
                {
                    field: "cursors",
                    operator: "eq",
                    value: cursors,
                }
            ];

            return filters;
        }
    });

    useEffect(() => {
        setFilters([{field: "cursors", operator: "eq", value: cursors}])
    }, [cursors, setFilters])

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <List>
                    <Table<ISupply> {...tableProps} rowKey="id">
                        <Table.Column dataIndex="id" title="id"/>
                        <Table.Column
                            dataIndex={["supplier", "name"]}
                            title="supplier"
                        />
                        <Table.Column
                            dataIndex={["warehouse", "name"]}
                            title="warehouse"
                        />
                        <Table.Column<ISupply>
                            title="Status"
                            align="center"
                            render={(_, record) => {
                                const color= (record.paidAt) ? "green" : "red"
                                return <Tag color={color}>
                                    {(record.paidAt)  ? <>Оплачено</> : <>Не оплачено</>}
                                </Tag>
                            }}
                        />
                        <Table.Column<ISupply>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <ShowButton hideText size="small" recordItemId={record.id}/>
                                </Space>
                            )}
                        />
                    </Table>
                    <CursorPagination data={tableQueryResult.data} cursors={cursors} setCursors={setCursors}/>
                </List>
            </Col>
        </Row>
    )
}
