import React from "react";
import {Edit, Form, Input, Select, useForm} from "@pankod/refine-antd";
import {HttpError, IResourceComponentsProps} from "@pankod/refine-core";
import {IUser, userStatuses} from "./model";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IUser, HttpError, IUser>({
        metaData: {
            fields: [
                "id",
                "email",
                "firstName",
                "lastName",
                "status"
            ]
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Email" name="email" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Password" name="password" rules={[{required: false}]}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item label="First Name" name="firstName" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Last Name" name="lastName" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Status" name="status" rules={[{required: true}]}>
                    <Select options={userStatuses} />
                </Form.Item>
            </Form>
        </Edit>
    );
};
