import React from "react";
import {Create, Form, Input, useForm} from "@pankod/refine-antd";
import {IResourceComponentsProps} from "@pankod/refine-core";
import {ICategory} from "./model";

export const CategoryCreate: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<ICategory>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Title" name="title" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
            </Form>
        </Create>
    );
};
