import {CrudFilters, CrudSorting, DataProvider, LogicalFilter} from "@pankod/refine-core";
import camelCase from "camelcase";
import {snakeCase} from "snake-case";
import * as gql from "gql-query-builder";
import {GraphQLClient} from "@pankod/refine-graphql";
import pluralize from "pluralize";

export type Cursors = {
    before?: string
    after?: string
    first?: number
    last?: number
}

export const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        return {
            field: snakeCase(sort[0].field).toUpperCase(),
            direction: sort[0].order.toUpperCase(),
        };
    }

    return null;
};

export const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: any } = {};

    if (filters) {
        filters.forEach((filter) => {
            if (
                filter.operator !== "or" &&
                filter.operator !== "and" &&
                "field" in filter
            ) {
                const {field, operator, value} = filter;

                if (operator === "eq") {
                    queryFilters[`${field}`] = value;
                } else if (["gte", "lte"].includes(operator)) {
                    const camelCaseField = camelCase(field)
                    const operatorUpperCase = operator.toUpperCase();
                    queryFilters[`${camelCaseField}${operatorUpperCase}`] = value;
                } else if (field.startsWith("has")) {
                    queryFilters["hasCategoryWith"] = [{idIn: value}];
                } else {
                    queryFilters[camelCase(`${field}_${operator}`)] = value;
                }
            } else {
                const value = filter.value as LogicalFilter[];

                const orFilters: any[] = [];
                value.forEach((val) => {
                    orFilters.push({
                        [`${val.field}_${val.operator}`]: val.value,
                    });
                });

                queryFilters["_or"] = orFilters;
            }
        });
    }

    return queryFilters;
};

export const dataProvider = (baseUrl: string): Required<DataProvider> => {
    const client = new GraphQLClient(`${baseUrl}/query`);

    return {
        getList: async ({resource, sort, filters, metaData}) => {
            const pagination = filters?.find((filter) => "field" in filter && filter?.field === "cursors")?.value || {limit: 3}
            const filtersWithoutCursors = filters?.filter((filter) => "field" in filter && filter?.field !== "cursors")

            const sortBy = generateSort(sort);

            const where = generateFilter(filtersWithoutCursors);
            const singularResource = camelCase(pluralize.singular(resource), {pascalCase: true});

            const operation = metaData?.operation || resource;

            const {query, variables} = gql.query({
                operation,
                variables: {
                    ...(sortBy ? {
                        orderBy: {
                            value: sortBy,
                            type: singularResource + "Order",
                        },
                    } : {}),
                    ...(pagination ? {
                        after: {
                            type: "Cursor",
                            value: pagination?.after,
                            required: false
                        },
                        before: {
                            type: "Cursor",
                            value: pagination?.before,
                            required: false
                        },
                        first: {
                            type: "Int",
                            value: pagination?.first,
                            required: false
                        },
                        last: {
                            type: "Int",
                            value: pagination?.last,
                            required: false
                        },
                        where: {
                            type: singularResource + "WhereInput",
                            value: where,
                        },
                    } : {})
                },
                fields: [
                    {
                        edges: [
                            {
                                node: metaData?.fields || ["id"]
                            }
                        ],
                    },
                    {
                        pageInfo: [
                            "endCursor",
                            "startCursor",
                            "hasPreviousPage",
                            "hasNextPage"
                        ]
                    },
                    "totalCount"
                ],
            });

            const response = await client.request(query, variables);
            return {
                data: response[operation].edges.map((e: any) => e.node),
                pageInfo: response[operation].pageInfo,
                total: response[operation].totalCount,
            };
        },

        getMany: async ({resource, ids, metaData}) => {
            throw Error("Not implemented");
        },

        create: async ({resource, variables, metaData}) => {
            const singularResource = pluralize.singular(resource);
            const camelCreateName = camelCase(`create-${singularResource}`);

            const operation = metaData?.operation ?? camelCreateName;

            const inputType = camelCreateName[0].toUpperCase() + camelCreateName.substr(1) + 'Input!';

            const {query, variables: gqlVariables} = gql.mutation({
                operation,
                variables: {
                    input: {
                        value: variables,
                        type: inputType,
                    },
                },
                fields: metaData?.fields ?? ["id"],
            });
            const response = await client.request(query, gqlVariables);

            return {
                data: response[operation][singularResource],
            };
        },

        createMany: async ({resource, variables, metaData}) => {
            throw Error("Not implemented");
        },

        update: async ({resource, id, variables, metaData}) => {
            const singularResource = pluralize.singular(resource);
            const camelUpdateName = camelCase(`update-${singularResource}`);

            const operation = metaData?.operation ?? camelUpdateName;

            const inputType = camelUpdateName[0].toUpperCase() + camelUpdateName.substr(1) + 'Input!';

            const {query, variables: gqlVariables} = gql.mutation({
                operation,
                variables: {
                    id: {
                        value: id,
                        type: "ID!",
                    },
                    input: {
                        value: variables,
                        type: inputType,
                    },
                },
                fields: metaData?.fields ?? ["id"],
            });
            const response = await client.request(query, gqlVariables);
            console.log(response)
            return {
                data: response[operation][singularResource],
            };
        },

        updateMany: async ({resource, ids, variables, metaData}) => {
            throw Error("Not implemented");
        },

        getOne: async ({resource, id, metaData}) => {
            const singularResource = pluralize.singular(resource);
            const camelResource = camelCase(singularResource);

            const operation = metaData?.operation ?? camelResource;

            const {query, variables} = gql.query({
                operation: "node",
                variables: {
                    id: {value: id, type: "ID", required: true},
                },
                fields: [
                    {
                        operation: operation[0].toUpperCase() + operation.substr(1),
                        fields: metaData?.fields,
                        fragment: true,
                    }
                ]
            });

            const response = await client.request(query, variables);

            return {
                data: response["node"],
            };
        },

        deleteOne: async ({resource, id, metaData}) => {
            const singularResource = pluralize.singular(resource);
            const camelDeleteName = camelCase(`delete-${singularResource}`);

            const operation = metaData?.operation ?? camelDeleteName;

            const {query, variables} = gql.mutation({
                operation,
                variables: {
                    id: {value: id, type: "ID", required: true},
                },
                fields: metaData?.fields ?? ["ok"],
            });

            const response = await client.request(query, variables);

            return {
                data: response[operation][singularResource],
            };
        },

        deleteMany: async ({resource, ids, metaData}) => {
            throw Error("Not implemented");
        },

        getApiUrl: () => {
            return baseUrl;
        },

        custom: async ({url, method, headers, metaData}) => {
            throw Error("Not implemented");
        },
    };
};
