import {
    Card,
    Col,
    EditButton, Form, FormProps,
    List,
    Row,
    Space,
    Table,
    Icons,
    useTable, Input, Button
} from "@pankod/refine-antd";
import {IFilter, IPlace} from "./model";
import React, {useEffect, useState} from "react";
import {CrudFilters, HttpError} from "@pankod/refine-core";
import {CursorPagination} from "components";
import {Cursors} from "../entDataProvider";

const DEFAULT_CURSORS = {first: 50};

export const PlaceList: React.FC = () => {
    const [cursors, setCursors] = useState<Cursors>(DEFAULT_CURSORS);

    const {tableProps, setFilters, tableQueryResult} = useTable<IPlace, HttpError, IFilter>({
        hasPagination: false,
        metaData: {
            fields: [
                "id",
                "name",
                "address",
            ],
        },
        initialFilter: [
            {
                field: "cursors",
                operator: "eq",
                value: DEFAULT_CURSORS,
            }
        ],
        onSearch: (params) => {
            const {cursors} = params;

            const filters: CrudFilters = [
                {
                    field: "cursors",
                    operator: "eq",
                    value: cursors,
                }
            ];

            return filters;
        }
    });

    useEffect(() => {
        setFilters([{field: "cursors", operator: "eq", value: cursors}])
    }, [cursors, setFilters])

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <List>
                    <Table<IPlace> {...tableProps} rowKey="id">
                        <Table.Column dataIndex="id" title="id"/>
                        <Table.Column dataIndex="name" title="name"/>
                        <Table.Column dataIndex="address" title="address"/>
                        <Table.Column<IPlace>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton hideText size="small" recordItemId={record.id}/>
                                </Space>
                            )}
                        />
                    </Table>
                    <CursorPagination data={tableQueryResult.data} cursors={cursors} setCursors={setCursors}/>
                </List>
            </Col>
        </Row>
    )
}
