import {
    Card,
    Col,
    DeleteButton,
    EditButton, Form, FormProps,
    List,
    Row,
    ShowButton,
    Space,
    Table,
    Icons,
    useTable, Input, Button, useSelect, Select, SelectProps
} from "@pankod/refine-antd";
import {IFilter, IProduct} from "./model";
import React, {useEffect, useState} from "react";
import {CrudFilters, HttpError} from "@pankod/refine-core";
import {CursorPagination} from "components";
import {Cursors} from "../entDataProvider";
import {ICategory} from "../categories";

const DEFAULT_CURSORS = {first: 3};

export const ProductList: React.FC = () => {
    const [cursors, setCursors] = useState<Cursors>(DEFAULT_CURSORS);

    const {tableProps, searchFormProps, setFilters, tableQueryResult} = useTable<IProduct, HttpError, IFilter>({
        hasPagination: false,
        // syncWithLocation: true,
        metaData: {
            fields: [
                "id",
                "name",
                "cost",
                "price",
                {
                    category: ["title"],
                },
            ],
        },
        initialFilter: [
            {
                field: "cursors",
                operator: "eq",
                value: DEFAULT_CURSORS,
            }
        ],
        onSearch: (params) => {
            const {query, categoryIDs, cursors} = params;

            const filters: CrudFilters = [
                {
                    field: "name",
                    operator: "contains",
                    value: query,
                },
                {
                    field: "hasCategoryIDs",
                    operator: "in",
                    value: categoryIDs,
                },
                {
                    field: "cursors",
                    operator: "eq",
                    value: cursors,
                }
            ];

            return filters;
        }
    });

    useEffect(() => {
        setFilters([{field: "cursors", operator: "eq", value: cursors}])
    }, [cursors, setFilters])

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        metaData: {
            fields: ["id", "title"],
        },
    });

    return (
        <Row gutter={[16, 16]}>
            <Col lg={6} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} categorySelectProps={categorySelectProps}/>
                </Card>
            </Col>
            <Col lg={18} xs={24}>
                <List>
                    <Table<IProduct> {...tableProps} rowKey="id">
                        {/*<Table.Column dataIndex="id" title="id"/>*/}
                        <Table.Column
                            dataIndex="name"
                            title="name"
                            sorter={true}
                        />
                        <Table.Column
                            dataIndex={["category", "title"]}
                            title="category"
                            // render={(_, record) => record.category.title}
                        />
                        <Table.Column
                            dataIndex="cost"
                            title="cost"
                            sorter={true}
                        />
                        <Table.Column
                            dataIndex="price"
                            title="price"
                            sorter={true}
                        />
                        <Table.Column<IProduct>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton hideText size="small" recordItemId={record.id}/>
                                    <ShowButton hideText size="small" recordItemId={record.id}/>
                                    <DeleteButton hideText size="small" recordItemId={record.id}/>
                                </Space>
                            )}
                        />
                    </Table>
                    <CursorPagination data={tableQueryResult.data} cursors={cursors} setCursors={setCursors}/>
                </List>
            </Col>
        </Row>
    )
}

const Filter: React.FC<{ formProps: FormProps, categorySelectProps: SelectProps }> = ({formProps, categorySelectProps}) => {
    return (
        <Form layout="vertical" {...formProps}>
            <Form.Item label="Search" name="query">
                <Input
                    placeholder="Name.."
                    prefix={<Icons.SearchOutlined/>}
                />
            </Form.Item>
            <Form.Item label="Category" name="categoryIDs">
                <Select
                    mode="multiple"
                    placeholder="Select Category"
                    {...categorySelectProps}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
