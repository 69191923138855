import React from "react";
import {
    Avatar,
    Edit,
    Form,
    getValueFromEvent,
    Input,
    Radio,
    Space,
    Typography,
    Upload,
    useForm
} from "@pankod/refine-antd";
import {HttpError, IResourceComponentsProps, useApiUrl} from "@pankod/refine-core";
import {IProduct} from "./model";

const {Text} = Typography;

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = useApiUrl();
    const {queryResult, formProps, saveButtonProps} = useForm<IProduct, HttpError, IProduct>({
        metaData: {
            fields: [
                "id",
                "name",
                "type",
                "unit",
                "cost",
                "price",
                "image",
            ]
        }
    });

    console.log(queryResult?.data?.data?.image)
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical"
                  onFinish={async (values) => {
                      // @ts-ignore
                      const {file, ...rest} = values;
                      if (file && file.length > 0) {
                          const imageUrl = file[0].response.file;
                          return (
                              formProps.onFinish &&
                              formProps.onFinish({
                                  ...rest,
                                  image: imageUrl,
                              })
                          );
                      }

                      return (
                          formProps.onFinish &&
                          formProps.onFinish(rest)
                      );
                  }}>
                <Form.Item>
                    <Form.Item
                        name="file"
                        valuePropName="fileList"
                        getValueFromEvent={getValueFromEvent}
                        noStyle
                    >
                        <Upload.Dragger
                            name="file"
                            action={`${apiUrl}/upload`}
                            listType="picture"
                            maxCount={1}
                            multiple={false}
                            style={{
                                border: "none",
                                width: "100%",
                                background: "none",
                            }}
                        >
                            <Space direction="vertical" size={2}>
                                <Avatar
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        maxWidth: "200px",
                                    }}
                                    src={queryResult?.data?.data?.image || "/images/product-default-img.png"}
                                />
                                <Text
                                    style={{
                                        fontWeight: 800,
                                        fontSize: "16px",
                                        marginTop: "8px",
                                    }}
                                >
                                    Add product image
                                </Text>
                                <Text style={{fontSize: "12px"}}>
                                    max size 5mb
                                </Text>
                            </Space>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="Name" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Type" name="type" rules={[{required: true}]}>
                    <Radio.Group>
                        <Radio value="goods">Goods</Radio>
                        <Radio value="service">Service</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Unit" name="unit" rules={[{required: true}]}>
                    <Radio.Group>
                        <Radio value="pt">pt</Radio>
                        <Radio value="g">g</Radio>
                        <Radio value="m">m</Radio>
                        <Radio value="l">l</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Cost" name="cost" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Price" name="price" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
