import React from "react";
import {
    Upload,
    Create,
    Form,
    getValueFromEvent,
    Input,
    Select,
    useForm,
    useSelect,
    Radio,
    Space, Avatar, Typography
} from "@pankod/refine-antd";
import {IResourceComponentsProps, useApiUrl} from "@pankod/refine-core";
import {IProduct} from "./model";
import {ICategory} from "../categories";

const {Text} = Typography;

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = useApiUrl();
    const {formProps, saveButtonProps} = useForm<IProduct>();
    const {selectProps: categorySelectProps} = useSelect<ICategory>({
        resource: "categories",
        metaData: {
            fields: ["id", "title"],
        },
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} initialValues={{type: "goods", unit: "g"}} layout="vertical"
                  onFinish={async (values) => {
                      // @ts-ignore
                      const {file, ...rest} = values;
                      if (file && file.length > 0) {
                          const imageUrl = file[0].response.file;
                          return (
                              formProps.onFinish &&
                              formProps.onFinish({
                                  ...rest,
                                  image: imageUrl,
                              })
                          );
                      }

                      return (
                          formProps.onFinish &&
                          formProps.onFinish(rest)
                      );
                  }}>
                <Form.Item>
                    <Form.Item
                        name="file"
                        valuePropName="fileList"
                        getValueFromEvent={getValueFromEvent}
                        noStyle
                    >
                        <Upload.Dragger
                            name="file"
                            action={`${apiUrl}/upload`}
                            listType="picture"
                            maxCount={1}
                            multiple={false}
                            style={{
                                border: "none",
                                width: "100%",
                                background: "none",
                            }}
                        >
                            <Space direction="vertical" size={2}>
                                <Avatar
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        maxWidth: "200px",
                                    }}
                                    src="/images/product-default-img.png"
                                />
                                <Text
                                    style={{
                                        fontWeight: 800,
                                        fontSize: "16px",
                                        marginTop: "8px",
                                    }}
                                >
                                    Add product image
                                </Text>
                                <Text style={{fontSize: "12px"}}>
                                    max size 5mb
                                </Text>
                            </Space>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="Name" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="categoryID"
                    rules={[{required: true}]}
                >
                    <Select {...categorySelectProps} />
                </Form.Item>
                <Form.Item label="Type" name="type" rules={[{required: true}]}>
                    <Radio.Group>
                        <Radio value="goods">Goods</Radio>
                        <Radio value="service">Service</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Unit" name="unit" rules={[{required: true}]}>
                    <Radio.Group>
                        <Radio value="pt">pt</Radio>
                        <Radio value="g">g</Radio>
                        <Radio value="m">m</Radio>
                        <Radio value="l">l</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Cost" name="cost" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Price" name="price" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
            </Form>
        </Create>
    );
};
