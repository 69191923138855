import React from "react";
import {
    Edit,
    Form,
    Input,
    Select,
    useForm, useSelect
} from "@pankod/refine-antd";
import {HttpError, IResourceComponentsProps} from "@pankod/refine-core";
import {ISupply} from "./model";
import {ISupplier} from "../suppliers";

export const SupplyEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<ISupply, HttpError, ISupply>({
        metaData: {
            fields: [
                "id",
                "name",
                "type",
                "unit",
                "cost",
                "price",
                "image",
            ]
        }
    });

    const {selectProps: supplierSelectProps} = useSelect<ISupplier>({
        resource: "suppliers",
        metaData: {
            fields: ["id", "paidAt", "comments"],
        },
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Name" name="name">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Supplier"
                    name="supplierID"
                    rules={[{required: true}]}
                >
                    <Select {...supplierSelectProps} />
                </Form.Item>
                <Form.Item label="Comments" name="comment">
                    <Input/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
