import React from "react";
import {RefreshButton, Show, Typography} from "@pankod/refine-antd";
import {IResourceComponentsProps, useShow} from "@pankod/refine-core";
import {ISupplier} from "./model";

const { Title, Text } = Typography;

const metaData = {
    fields: [
        "id",
        "name",
        "email",
        "phone",
        "comment",
    ],
};

export const SupplierShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<ISupplier>({
        metaData,
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading} headerProps={{ extra: <RefreshButton metaData={metaData} />}}>
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>
        </Show>
    );
};
